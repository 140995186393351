import React from 'react'
import PropTypes from 'prop-types'

const remark = require("remark");
const remarkHTML = require("remark-html");

export const HTMLContent = ({ content, className }) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
)

// Sometimes we end up with Markdown in the templates because some limitations
// on easily parsing nested frontmatter fields with Markdown, so we expose this
// parser to components (like the Exec Bios)
export const MarkdownContent = ({ content, className }) => {
  const parsed = remark()
          .use(remarkHTML)
          .processSync(content)
          .toString();
  return <div className={className} dangerouslySetInnerHTML={{ __html: parsed }} />
}

const Content = ({ content, className }) => (
  <div className={className}>{content}</div>
)

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
}

HTMLContent.propTypes = Content.propTypes

export default Content
